export const tokens = {
	"cult": {
		"name": "CULT",
		"address": "0x4Ed9C2aba77141433c462B8EE835B7fC39Ec449A",
		"icon": require("../images/token-icons/cult.jpg_medium"),
		"abi": require("../abis/cult.json")
	}, 
	"xcult": {
		"name": "xCULT",
		"address": "0x718670c88f2daa222d44908c1c253253ff96ae21",
		"icon": require("../images/token-icons/x.png"),
		"abi": require("../abis/cult.json")
	}, 
	"candle": {
		"name": "CANDLE",
		"address": "0x208234F4f8B1bBEA59cfDc38666141654e851DCe",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	}, 
	"soul": {
		"name": "SOUL",
		"address": "0xe2fb177009FF39F52C0134E8007FA0e4BaAcBd07",
		"icon": require("../images/token-icons/soul.png"),
		"abi": require("../abis/GenericABI.json")
	},
	"cult-soul": {
		"name": "CULT-SOUL SoulSwap LP",
		"address": "0x4AB8b967F75cf860b27Ebd1F6a174aD440F754FC",
		"tokens": ["cult","soul"],
		"dex": "SoulSwap",
		"icon": require("../images/token-icons/cult.jpg_medium"),
		"abi": require("../abis/GenericABI.json")
	},
	"xcult-soul": {
		"name": "xCULT-SOUL SoulSwap LP",
		"address": "0xc8Ad1928ba95f29f967d6CB2E232Fd722C4089B2",
		"tokens": ["xcult","soul"],
		"dex": "SoulSwap",
		"icon": require("../images/token-icons/x.png"),
		"abi": require("../abis/GenericABI.json")
	},
	"candle-soul": {
		"name": "CANDLE-SOUL SoulSwap LP",
		"address": "0xf58bc729013FD106619241052a5182C06D948edA",
		"tokens": ["candle","soul"],
		"dex": "SoulSwap",
		"icon": require("../images/token-icons/candle.jpg_small"),
		"abi": require("../abis/GenericABI.json")
	},
}

export const lps = {
	"cult-soul": {
		"name": "CULT-SOUL SoulSwap LP",
		"address": "0x4AB8b967F75cf860b27Ebd1F6a174aD440F754FC",
		"tokens": ["cult","soul"],
		"dex": "SoulSwap"
	},
	"xcult-soul": {
		"name": "xCULT-SOUL SoulSwap LP",
		"address": "0xc8Ad1928ba95f29f967d6CB2E232Fd722C4089B2",
		"tokens": ["xcult","soul"],
		"dex": "SoulSwap"
	},
	"candle-soul": {
		"name": "CANDLE-SOUL SoulSwap LP",
		"address": "0xf58bc729013FD106619241052a5182C06D948edA",
		"tokens": ["candle","soul"],
		"dex": "SoulSwap"
	},
}

export const farms = {
	"burn": {
		"address": "0x1602439C3D4E28936004ACbCc7850D3F0Ce2fFd9",
		"abi": require("../abis/CandleBurnFarmV2.json")
	},
	"gate": {
		"address": "0xACc1c939b42A1a0e68C2975aB7B46BD8D168126C",
		"abi": require("../abis/CandleGatedChef.json")
	}, 
	"degenesis": {
		"address": "0x2462FA2Bc4B0Cbc501c8546081556fF4F84162De",
		"abi": require("../abis/CandleDegenesisABI.json")
	}
}