import React from 'react';
import Web3 from 'web3';
import { chainMap, enforceChain } from './tools/ChainTools.js'
import { degenesis, dialog } from './configs/CandleDegenesisConfig.js'
import { tokens, lps } from './configs/CultTokensConfig.js'
import { nfts } from './configs/CultNFTConfig.js' 
import { CF } from './tools/CF.js'
import { fromAddressTo, formatTokenBalance, fromTokenNameToAddress, tokenNameAddyMap, fromTokenNameToDecimals } from './tools/tokenUtilities.js'
const bg = require("./images/store-front.gif") 
const pools = ["cult","xcult","cult-soul","xcult-soul"]
const fs = (bal, dec = 18) => {
  let x = bal.toString()
  let digits, decimals
  let L = "0", R = "0"
  let output
  //console.log(x.length, dec)
  if (x.length > dec) {
    digits = x.length - dec
    L = x.slice(0,digits)
    R = x.slice(digits)
  } else {
    decimals = dec - x.length
    R = "0".repeat(decimals).concat(x)
  }
  output = L.concat(".").concat(R)
  output = new Intl.NumberFormat().format(output)
  return output
}

const InputView = (props) => (

  <div className={"theInput TOGGLE__theInput--" + props.toggle + " INTENT__theInput--" + props.intent}>
        <input type="text" id="theInput" defaultValue="0" ref={props.theInputRef} />
        <button className="btn btn--max" onClick={props.setInputRefToMAX}>MAX</button>
        <div className="theInput__poolbalance">Pool Balance: <span>{props.poolBalance}</span></div>

        <div className="theInput__balance">Balance: <span>{props.balance}</span></div>
        <div className="theInput__allowance">Allowance: <span>{props.allowance}</span></div>
        <button className="btn btn--back" onClick={props.toggleInput}>back</button>
        <button className="btn btn--approve" onClick={props.onClick_Approve}>Approve This Amount</button>
        <button className="btn btn--deposit" onClick={props.triggerDeposit}>Deposit This Amount</button>
        <button className="btn btn--withdraw" onClick={props.triggerWithdraw}>Withdraw This Amount</button>
      </div>
      )

export function Dapp() {
  // this is a reference to the input field
  const theInputRef = React.createRef();
  const [theTargetPool, setTheTargetPool] = React.useState(0)

  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (!connected) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)})
      }
  }

  
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
        window.ethereum.on('chainChanged', handleChainChange);
      };
    }
  }, [connected]);



  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);

var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var skullys = new web3.eth.Contract(
  nfts["skullys"]["abi"], 
  nfts["skullys"]["address"])

var popskullys = new web3.eth.Contract(
  nfts["popskullys"]["abi"], 
  nfts["popskullys"]["address"])

var ghostlys = new web3.eth.Contract(
  nfts["ghostlys"]["abi"], 
  nfts["ghostlys"]["address"])

var cult = new web3.eth.Contract(
  tokens["cult"]["abi"], 
  tokens["cult"]["address"])

var xcult = new web3.eth.Contract(
  tokens["xcult"]["abi"], 
  tokens["xcult"]["address"])

var candle = new web3.eth.Contract(
  tokens["candle"]["abi"], 
  tokens["candle"]["address"])

var soul = new web3.eth.Contract(
  tokens["soul"]["abi"],
  tokens["soul"]["address"]
  )

var degen = new web3.eth.Contract(
  degenesis["abi"],
  degenesis["address"]
  )

var cultItems = new web3.eth.Contract(
  nfts["items"]["abi"],
  nfts["items"]["address"]
  )

var itemIds = [1, 2, 11, 12, 13, 21, 22]

var dgn = new CF(web3,"degenesis",theInputRef)
var farm_burn = new CF(web3,"burn",theInputRef)
var farm_gate = new CF(web3,"gate",theInputRef)

const [poolLength, setPoolLength] = React.useState(0)
const getPoolLength = () => {
  degenesis.methods.poolLength()
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setPoolLength(Number(res))
    })
}

// get the poolInfo for each pool
const [poolInfo0,setPoolInfo0] = React.useState({})
const [poolInfo1,setPoolInfo1] = React.useState({})
const [poolInfo2,setPoolInfo2] = React.useState({})
const [poolInfo3,setPoolInfo3] = React.useState({})
const getPoolInfo = (pid) => {
    return () => {
      degenesis.methods.poolInfo(pid)
        .call({from: window.ethereum.selectedAddress})
        .then((res) => {
          eval("setPoolInfo" + pid.toString() + "(res)")
        })
    }
  }
// get the userInfo for each pool
  const [userInfo0,setUserInfo0] = React.useState({})
  const [userInfo1,setUserInfo1] = React.useState({})
  const [userInfo2,setUserInfo2] = React.useState({})
  const [userInfo3,setUserInfo3] = React.useState({})
  const getUserInfo = (pid) => {
    return () => {
      degen.methods.userInfo(pid,window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
        .then((res) => {
          eval("setUserInfo" + pid.toString() + "(res)")
        })
    }
  }
// get the userInfo for each pool
const [pending0,setPending0] = React.useState({})
const [pending1,setPending1] = React.useState({})
const [pending2,setPending2] = React.useState({})
const [pending3,setPending3] = React.useState({})

const getPending = (pid) => {
  return () => {
    degen.methods.pendingCandle(pid,window.ethereum.selectedAddress)
      .call({from: window.ethereum.selectedAddress})
      .then((res) => {
        eval("setPending" + pid.toString() + "(res)")
      })
  }
}

// when the poolLength changes from 0
  // get the poolInfo for each pool
  React.useEffect(() => {
    if (poolLength > 0) {
      let f, g, h
      for (var i = 0; i < poolLength; i++) {
        f = getPoolInfo(i)
        g = getUserInfo(i)
        h = getPending(i)
        f()
        g()
        h()
      }
    }
  }, [poolLength])

// this function checks if all the info is fetched from the chain
  const isInfoReady = () => {
    let x = true
    var poolCheck = "'0' in poolInfo"
    var userCheck = "'0' in userInfo"
    for (var i = 0; i < poolLength; i++) {
      console.log(poolCheck + i.toString())
      console.log(eval(poolCheck + i.toString()))
      console.log(poolInfo0)
      if (!eval(poolCheck + i.toString()) || !eval(userCheck + i.toString())) {
        x = false
        break
      }
    }
    console.log(x, "isInfoReady")
    return x;
  }

// this state manages the intent of the input overlay
  // it should be set to false initially,
  // and then set to either "add" or "remove"
  const [theInputINTENT, setTheInputINTENT] = React.useState(false);

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetADDY, setTheTargetADDY] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the address of the staking token for the target pool
  const [theTargetNAME, setTheTargetNAME] = React.useState("");

  // when a user opens the input overlay,
  // this state gets set to the pool ID of the target pool
  const [theTargetPOOL, setTheTargetPOOL] = React.useState(0);

  // this state manages the display of the input overlay
  const [theInputTOGGLE, setTheInputTOGGLE] = React.useState(false);

  // this function manages the toggling of theInputTOGGLE state
  const toggleInput = () => {
    console.log(theInputTOGGLE, "toggling")
    if (theInputTOGGLE) {
      setTheInputTOGGLE(false)
    } else {
      setTheInputTOGGLE(true)
    }
  }

 const setInputRefToMAX = () => {
    if (theInputINTENT == "remove") {
      theInputRef.current.value = formatTokenBalance(
        fromTokenNameToDecimals(theTargetNAME), 
        eval("userInfo" + theTargetPOOL +"['0']"), 
        false)
    } else {
      
      theInputRef.current.value = formatTokenBalance(
        fromTokenNameToDecimals(theTargetNAME), 
        balanceOfTarget, 
        false)
    }
    
  }
 // state for storing,
  // and function for setting,
  // the input overlay's display of the user's wallet balance of the staking token
  const [balanceOfTarget, setBalanceOfTarget] = React.useState(0);
  const smartSetBalanceOfTarget = (balance) => {
    console.log(balance, "balance of target")
    setBalanceOfTarget(balance)
  }

  // state for storing,
  // and function for setting,
  // the input overlay's display of the user's allowance (to the CHEF) of the staking token
  const [allowanceOfTarget, setAllowanceOfTarget] = React.useState(0);
  const smartSetAllowanceOfTarget = (allowance) => {
    console.log(allowance, "allowance of target")
    setAllowanceOfTarget(allowance)
  }
// onClick function for approving a given amount of the staking token
  const onClick_Approve = () => {
    setIsPending(true)
    dgn.triggerApproval(
      theTargetADDY, 
      (allowance) => {
        smartSetAllowanceOfTarget(allowance)
        setIsPending(false)
      } )
  }
  

  // onClick function for depositing to theTargetPOOL
  const triggerDeposit = () => {
    setIsPending(true)
    console.log("target pool: " + theTargetPOOL)
    dgn.depositAmount(
      theTargetPOOL, pools[theTargetPOOL],
      () => {
        setIsPending(false)
        toggleInput();
        let f = getUserInfo(theTargetPOOL)
        f()
      }
    )
  }

  // onClick function for withdrawing from theTargetPOOL
  const triggerWithdraw = () => {
    setIsPending(true)
    dgn.withdrawAmount(
      theTargetPOOL, pools[theTargetPOOL],
      () => {
        setIsPending(false)
        toggleInput();
        let f = getUserInfo(theTargetPOOL)
        f()
      }
    )
  }
const letItRip = () => {
  getUserBalances()
}

const [balSkully, setBalSkully] = React.useState(0)
const [balPopSkully, setBalPopSkully] = React.useState(0)
const [balGhostly, setBalGhostly] = React.useState(0)

const [bal_item1, set_bal_item1] = React.useState(0)
const [bal_item2, set_bal_item2] = React.useState(0)
const [bal_item11, set_bal_item11] = React.useState(0)
const [bal_item12, set_bal_item12] = React.useState(0)
const [bal_item13, set_bal_item13] = React.useState(0)
const [bal_item21, set_bal_item21] = React.useState(0)
const [bal_item22, set_bal_item22] = React.useState(0)

const get1155Balance = (nft, id, setState) => {
  nft.methods.balanceOf(window.ethereum.selectedAddress, id)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setState(res)
    })
}

const getNFTBalance = (nft, setState) => {
  nft.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setState(res)
    })
}

const [balCult, setBalCult] = React.useState(0)
const [balXCult, setBalXCult] = React.useState(0)
const [balCandle, setBalCandle] = React.useState(0)

const getTokenBalance = (token, setState) => {
  token.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setState(res)
    })
}

const getUserBalances = () => {
  getNFTBalance(skullys, setBalSkully)
  getNFTBalance(popskullys, setBalPopSkully)
  getNFTBalance(ghostlys, setBalGhostly)
  getTokenBalance(cult, setBalCult)
  getTokenBalance(xcult, setBalXCult)
  getTokenBalance(candle, setBalCandle)

  get1155Balance(cultItems, 1, set_bal_item1)
  get1155Balance(cultItems, 2, set_bal_item2)
  get1155Balance(cultItems, 11, set_bal_item11)
  get1155Balance(cultItems, 12, set_bal_item12)
  get1155Balance(cultItems, 13, set_bal_item13)
  get1155Balance(cultItems, 21, set_bal_item21)
  get1155Balance(cultItems, 22, set_bal_item22)
}

const [isControlsOpen, setIsControlsOpen] = React.useState(false)
const toggleControls = () => {
  if (isControlsOpen) {
    setIsControlsOpen(false)
  } else {
    setIsControlsOpen(true)
  }
}

const [isInventoryOpen, setIsInventoryOpen] = React.useState(false)
const [is1155InventoryOpen, setIs1155InventoryOpen] = React.useState(false)
const toggle = (state, setState) => {
  if (state) {
    setState(false)
  } else {
    setState(true)
  }
}

const toggleInventory = () => {
  console.log("toggling inventory", (isInventoryOpen) ? "off" : "on")
  if (isInventoryOpen) {
    setIsInventoryOpen(false)
    console.log("inventory is toggled off")
  } else {
    setIsInventoryOpen(true)
    if (is1155InventoryOpen) {
      setIs1155InventoryOpen(false)
    }
    console.log("inventory is toggled on")
    console.log("and the state is ", isInventoryOpen)
  }
}
const toggle1155Inventory = () => {
  console.log("toggling 1155 inventory", (is1155InventoryOpen) ? "off" : "on")
  if (is1155InventoryOpen) {
    setIs1155InventoryOpen(false)
    console.log("1155 inventory is toggled off")
  } else {
    setIs1155InventoryOpen(true)
    if (isInventoryOpen) {
      setIsInventoryOpen(false)
    }
    console.log("1155 inventory is toggled on")
    console.log("and the state is ", is1155InventoryOpen)
  }
}

const [activeDialogIndex, setActiveDialogIndex] = React.useState(0)
const [isDialogOpen, setIsDialogOpen] = React.useState(false)
const [isStoreOpen, setIsStoreOpen] = React.useState(false)
const toggleStore = () => {
  console.log("toggling store", (isStoreOpen) ? "off" : "on")
  if (isStoreOpen) {
    setIsStoreOpen(false)
    console.log("store is toggled off")
  } else {
    setIsStoreOpen(true)
   
    console.log("store is toggled on")
    console.log("and the state is ", isStoreOpen)
  }
}
const goToDialog = (n) => {
  return () => {
    if (n == "pool_cult") {
      setIsDialogOpen(false)
      setIsPoolOpen_Cult(true)
    } else if (n == "pool_xcult") {
      setIsDialogOpen(false)
      setIsPoolOpen_xCult(true)
    } else if (n == "pool_cultlp") {
      setIsDialogOpen(false)
      setIsPoolOpen_CultLP(true)
    } else if (n == "pool_xcultlp") {
      setIsDialogOpen(false)
      setIsPoolOpen_xCultLP(true)
    } else {
      setActiveDialogIndex(n)
    }
    
  }
}

const [isPoolOpen_Cult, setIsPoolOpen_Cult] = React.useState(false)
const [isPoolOpen_xCult, setIsPoolOpen_xCult] = React.useState(false)
const [isPoolOpen_CultLP, setIsPoolOpen_CultLP] = React.useState(false)
const [isPoolOpen_xCultLP, setIsPoolOpen_xCultLP] = React.useState(false)
  const openInputViewAndDoStuff = (pid, intent) => {
  
    let theAddy = tokens[pools[pid]]["address"]
    let theName = tokens[pools[pid]]["name"]
    let thePool = pid
    return () => {

      toggleInput()
      setTheTargetADDY(theAddy)
      setTheInputINTENT(intent)
      setTheTargetNAME(theName)
      setTheTargetPOOL(thePool)
      dgn.getBalance(theAddy, (bal) => smartSetBalanceOfTarget(bal))
      dgn.checkAllowance(theAddy, (allowance) => smartSetAllowanceOfTarget(allowance))
    }

  }


    // onClick function factory that returns appropriate harvest function
  const returnHarvestFunction = (id) => {
    return () => {
      setIsPending(true)
      dgn.harvest(
        id,
        () => {
          setIsPending(false)
          //update pending rewards
          let f = getPending(id)
          f()
        })
    }     
  }

  const _f = (str) => {
    return str.substring(0, str.length - 18)
  }

  const _g = (str) => {
    if (typeof str != "string") {

    } else {
      if (str.length - 18 > 0) {
        return Number(str.substring(0, str.length - 18))
      } else {
        return Number(str.substring(0, str.length - 15))/1000;
      }
    }  
  }

  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_Cult) {
      x = getUserInfo(0)
      x()
      y = getPending(0)
      y()
    }
  },[isPoolOpen_Cult])

  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_xCult) {
      x = getUserInfo(1)
      x()
      y = getPending(1)
      y()
    }
  },[isPoolOpen_xCult])

  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_CultLP) {
      x = getUserInfo(2)
      x()
      y = getPending(2)
      y()
    }
  },[isPoolOpen_CultLP])
  React.useEffect(()=>{
    let x,y,z
    if (isPoolOpen_xCultLP) {
      x = getUserInfo(3)
      x()
      y = getPending(3)
      y()
    }
  },[isPoolOpen_xCultLP])


  const mint1155 = (itemId) => {
    return () => {
      setIsPending(true)
      candle.methods.allowance(
        window.ethereum.selectedAddress,
        nfts["items"]["address"]
        ).call({from: window.ethereum.selectedAddress})
        .then((allw) => {
          if (allw.toString().length < 21) {
             candle.methods.approve(nfts["items"]["address"], "1000000000000000000000000")
              .send({from: window.ethereum.selectedAddress})
              .then((res0) => {
                cultItems.methods.mintItem(itemId)
                  .send({from: window.ethereum.selectedAddress})
                  .then((res) => {
                    setIsPending(false)
                  }).catch((err)=>{
                    setIsPending(false)
                  })
              }).catch((err)=>{
                    setIsPending(false)
                  })
          } else {
            cultItems.methods.mintItem(itemId)
              .send({from: window.ethereum.selectedAddress})
              .then((res) => {
                setIsPending(false)
              }).catch((err)=>{
                setIsPending(false)
              })
          }
        })
      
    }
  }

  const [minted_item1, set_minted_item1] = React.useState(0)
  const [minted_item2, set_minted_item2] = React.useState(0)
  const [minted_item11, set_minted_item11] = React.useState(0)
  const [minted_item12, set_minted_item12] = React.useState(0)
  const [minted_item13, set_minted_item13] = React.useState(0)
  const [minted_item21, set_minted_item21] = React.useState(0)
  const [minted_item22, set_minted_item22] = React.useState(0)

  const getMintedAmount = (id, setState) => {
    cultItems.methods.getItem(id)
      .call({from: window.ethereum.selectedAddress})
      .then((res)=>{
        console.log(res["6"])
        setState(res["6"])
      })
  }

  React.useEffect(()=>{
    if (isStoreOpen) {
      getMintedAmount(1, set_minted_item1)
      getMintedAmount(2, set_minted_item2)
      getMintedAmount(11, set_minted_item11)
      getMintedAmount(12, set_minted_item12)
      getMintedAmount(13, set_minted_item13)
      getMintedAmount(21, set_minted_item21)
      getMintedAmount(22, set_minted_item22)

    }
  },[isStoreOpen])
  return (
    <div className={"App-wrapper"}>

      <div className={"dialog dialog--" + isDialogOpen}>
        <div className="dialog__active">
          <div className="dialog__speaker">
            <img src={dialog[activeDialogIndex.toString()]["speaker"].default}/>
          </div>
          <div className="dialog__text">
          {
            dialog[activeDialogIndex.toString()]["text"].map(p=>(<p>{p}</p>))
          }
          </div>
        </div>
        <div className="dialog__options">
          
            {
              dialog[activeDialogIndex.toString()]["options"].map(
                (btn,index)=>(
                  <button 
                    key={index}
                    className="dialog__option"
                    onClick={
                      goToDialog(
                        dialog[activeDialogIndex.toString()]["optiontarget"][index])}>
                    {btn}
                  </button>
                  ))
            }
          
        </div>

      </div>

      <div className={"pool pool-open--" + isPoolOpen_Cult}>
        <h2>You found a $CANDLE (de)genesis pool. Deposit $CULT to earn $CANDLE.</h2>
        <div className="pool__manage">

          <div className="pool__token"><img src={tokens["cult"]["icon"]["default"]} /></div>
          <strong>{_g(userInfo0['0'])} $CULT</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(0, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(0, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending0)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(0)}>harvest</button>
          </div>
        </div>
      </div>

      <div className={"pool pool-open--" + isPoolOpen_xCult}>
        <h2>You found a $CANDLE (de)genesis pool. Deposit $xCULT to earn $CANDLE.</h2>
        <div className="pool__manage">

          <div className="pool__token"><img src={tokens["xcult"]["icon"]["default"]} /></div>
          <strong>{_g(userInfo1['0'])} $xCULT</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(1, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(1, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending1)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(1)}>harvest</button>
          </div>
        </div>
      </div>

      <div className={"pool pool-open--" + isPoolOpen_CultLP}>
        <h2>You found a $CANDLE (de)genesis pool. Deposit $CULT-$SOUL LP to earn $CANDLE.</h2>
        <div className="pool__manage">

          <div className="pool__token">
            <img src={tokens["cult"]["icon"]["default"]} />
            <img src={tokens["soul"]["icon"]["default"]} />
          </div>
          <strong>{_g(userInfo1['2'])} $CULT-$SOUL LP</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(2, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(2, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending2)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(2)}>harvest</button>
          </div>
        </div>
      </div>

      <div className={"pool pool-open--" + isPoolOpen_xCultLP}>
        <h2>You found a $CANDLE (de)genesis pool. Deposit $xCULT-$SOUL LP to earn $CANDLE.</h2>
        <div className="pool__manage">

          <div className="pool__token">
            <img src={tokens["xcult"]["icon"]["default"]} />
            <img src={tokens["soul"]["icon"]["default"]} />
          </div>
          <strong>{_g(userInfo1['3'])} $xCULT-$SOUL LP</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(3, "add")}>+</button>
            <button className="pool__btn" 
              onClick={openInputViewAndDoStuff(3, "remove")}>-</button>
          </div>
        </div>
        <div className="pool__harvest">
          <div className="pool__token"><img src={tokens["candle"]["icon"]["default"]} /></div>
          <strong>{formatTokenBalance(
            18, 
            pending2)} $CANDLE</strong>
          <div className="block-buttons">
            <button className="pool__btn" 
              onClick={returnHarvestFunction(3)}>harvest</button>
          </div>
        </div>
      </div>



        <button 
          disabled={isDisabled} 
          onClick={connectMM} 
          className="mmbtn">
          {mmBtnText}
        </button>

        <div className={"store store-open--" + isStoreOpen}>
          <div className={"store__img"}><img src={bg.default} /></div>
          <div className="store__options">
            <button className="store__option" onClick={mint1155(1)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
              <span>Apple</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{minted_item1} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(2)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
              <span>Apple (..bitten)</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{minted_item2} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(11)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
              <span>Briefcase</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{minted_item11} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(12)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
              <span>Cult Briefcase</span>
              <span>1,000 $CANDLE</span>
              <span className="smaller">{minted_item12} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(13)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
              <span>Bone Handle Briefcase</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{minted_item13} / 100 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(21)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
              <span>Skeleton Key</span>
              <span>1 $CANDLE</span>
              <span className="smaller">{minted_item21} / 1,000,000 minted</span>
            </button>
            <button className="store__option" onClick={mint1155(22)}>
              <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
              <span>Bone Key</span>
              <span>3,000 $CANDLE</span>
              <span className="smaller">{minted_item22} / 100 minted</span>
            </button>
          </div>
        </div>

      <div 
        className={"inventory inventory--1155 inventory-open--" + is1155InventoryOpen}>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple.png" />
          <div className="inv-bal">{bal_item1} <span>Apple</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/apple_bite.png" />
          <div className="inv-bal">{bal_item2} <span>Bitten Apple</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_plain.png" />
          <div className="inv-bal">{bal_item11} <span>Briefcase</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase.png" />
          <div className="inv-bal">{bal_item12} <span>Cult Briefcase</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/briefcase_bone.png" />
          <div className="inv-bal">{bal_item13} <span>Bone Handle Briefcase</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key.png" />
          <div className="inv-bal">{bal_item21} <span>Skeleton Key</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src="https://candle.farmgod.finance/static/media/key_bone.png" />
          <div className="inv-bal">{bal_item22} <span>Bone Key</span></div>
        </div>
     </div>
     <button
        className="btn-inventory--1155"
        onClick={toggle1155Inventory}>
        Item Inventory
      </button>

      <div 
        className={"inventory inventory-open--" + isInventoryOpen}>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src={nfts["skullys"]["img"].default} />
          <div className="inv-bal">{balSkully} <span>Skullys</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src={nfts["popskullys"]["img"].default} />
          <div className="inv-bal">{balPopSkully} <span>Pop Skullys</span></div>
        </div>
        <div className="inv-item inv-item--nft">
          <img className="nft-thumb" src={nfts["ghostlys"]["img"].default} />
          <div className="inv-bal">{balGhostly} <span>Ghostlys</span></div>
        </div>

        
        

        <div className="inv-item inv-item--token">
          <img className="token-icon" src={tokens["cult"]["icon"].default} />
          <div className="inv-bal">{fs(balCult)} <span>CULT</span></div>
        </div>
        <div className="inv-item inv-item--token">
          <img className="token-icon" src={tokens["xcult"]["icon"].default} />
          <div className="inv-bal">{fs(balXCult)} <span>xCULT</span></div>
        </div>
        <div className="inv-item inv-item--token">
          <img className="token-icon" src={tokens["candle"]["icon"].default} />
          <div className="inv-bal">{fs(balCandle)} <span>CANDLE</span></div>
        </div>
      </div>

      <button
        className="btn-inventory"
        onClick={toggleInventory}>
        Token Inventory
      </button>


     {
      <InputView 
        toggle={theInputTOGGLE}
        intent={theInputINTENT}
        theInputRef={theInputRef}
        setInputRefToMAX={setInputRefToMAX}
        poolBalance={
          formatTokenBalance(
            fromTokenNameToDecimals(theTargetNAME), 
            eval("userInfo" + theTargetPOOL + "['0']"))}
        balance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), balanceOfTarget)}
        allowance={formatTokenBalance(fromTokenNameToDecimals(theTargetNAME), allowanceOfTarget)}
        toggleInput={toggleInput}
        onClick_Approve={onClick_Approve}
        triggerDeposit={triggerDeposit}
        triggerWithdraw={triggerWithdraw} />
      }

      <div className={"pending pending--" + isPending}>Pending Transaction</div>


      <button 
        className={"start-over start-over--" + isDialogOpen}
        onClick={() => {
          setActiveDialogIndex(0)
          setIsDialogOpen(true)
          setIsPoolOpen_Cult(false)
          setIsPoolOpen_xCult(false)
          setIsPoolOpen_CultLP(false)
          setIsPoolOpen_xCultLP(false)
        }}>
        Start Over
      </button>

      <button
        className={"start-over"}
        onClick={toggleStore}>
        Item Shop
      </button>
    </div>

  );
}

