import { tokens, lps } from '../configs/CultTokensConfig.js'
import numeral from 'numeral'
const tokenConfigs = tokens
function createNameIconMap (_tokenNames) {
	let _tokenNameIconMap = new Map();

	_tokenNames.forEach(_tokenName => {
	  _tokenNameIconMap.set(_tokenName, {icon: tokenConfigs[_tokenName]["icon"]["default"]} )
	}) 

	return _tokenNameIconMap
}

function createNameAddyMap (_tokenNames) {
  let _tokenNameAddyMap = new Map();

  _tokenNames.forEach(_tokenName => {
    _tokenNameAddyMap.set(tokenConfigs[_tokenName]["address"], {name: _tokenName})
  }) 

  return _tokenNameAddyMap
}

export const tokenNames = Object.keys(tokenConfigs)
export const tokenNameIconMap = createNameIconMap(tokenNames)
export const tokenNameAddyMap = createNameAddyMap(tokenNames)


export const formatTokenBalance = (decimals, bal, sciNote = true) => {
  //console.log("formatting "+bal)
  let lpTokenDecimals = decimals
  let formattedBalance = 0
  let _bal = bal/10 ** lpTokenDecimals

  if (sciNote) {
    if (_bal > 10 ** 6) {
      formattedBalance = numeral(_bal).format("0.00 a")
    } else if (_bal <= 10 ** 6 && _bal > 10 ** 1) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0")
    } else if (_bal <= 10 ** 1 && _bal > 10 ** (-2) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.000")
    }  else if (_bal <= 10 ** 1 && _bal > 10 ** (-3) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.0000")
    }  else if (_bal <= 10 ** 1 && _bal > 10 ** (-4) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.00000")
    }  else if (_bal <= 10 ** 1 && _bal > 10 ** (-5) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.000000")
    } else if ( _bal > 0 ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.00e+0")
    } else {
      formattedBalance = "--"
    }
  } else {
    if (_bal > 10 ** 6) {
      formattedBalance = numeral(_bal).format("0,0")
    } else if (_bal <= 10 ** 6 && _bal > 10 ** 1) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.0000")
    } else if (_bal <= 10 ** 1 && _bal > 10 ** (-2) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.00000")
    }  else if (_bal <= 10 ** 1 && _bal > 10 ** (-3) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.000000")
    }  else if (_bal <= 10 ** 1 && _bal > 10 ** (-4) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.0000000")
    }  else if (_bal <= 10 ** 1 && _bal > 10 ** (-5) ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.00000000")
    } else if ( _bal > 0 ) {
      formattedBalance = numeral(bal / 10 ** lpTokenDecimals).format("0,0.000000000")
    } else {
      formattedBalance = "--"
    }
  }

  

  

  return formattedBalance
}

export const fromTokenNameToIcon = (tokenName) => {
  return (tokenName) ? tokenConfigs[tokenName]["icon"] : ""
}

export const fromTokenNameToAddress = (tokenName) => {
  return (tokenName) ? tokenConfigs[tokenName]["address"] : ""
}

export const fromTokenNameToDecimals = (tokenName) => {
  return (typeof tokenConfigs[tokenName] !== 'undefined') ? tokenConfigs[tokenName]["decimals"] : 18
}

export const fromTokenNameTo = (tokenName, to) => {
 
  switch (to) {
    case "icon":
      return fromTokenNameToIcon(tokenName)
    case "address":
      return fromTokenNameToAddress(tokenName)
    case "decimals":
      return fromTokenNameToDecimals(tokenName)
    default:
      return tokenConfigs[tokenName]["name"]
    
  }
	
}

export const searchAddress = (addy, get) => {
  let y = null
  let addyy = addy.toLowerCase()
  //console.log(addyy)
  tokenNames.forEach( (x, index) => {
    if (typeof tokenConfigs[x]["address"] !== 'undefined') {

      if (tokenConfigs[x]["address"].toLowerCase() == addyy) {
        //console.log("Match "+ tokenConfigs[x]["address"])
        y = x
      }
    }
    
  })
    
  
  return y
}

export const fromAddressTo = (addy, to) => {
  searchAddress(addy, to)
}




export const cleanFetchedName = (dirtyName) => {
  if (typeof dirtyName === 'undefined') return dirtyName
  let clean = dirtyName.toLowerCase()
  clean = clean.replace(/[()]/g,'')
  clean = clean.replace(/ pos/g,'')
  clean = clean.replace(/pos /g,'')
  clean = clean.replace(/ token/g,'')


  switch (clean) {
    case "wrapped matic":
      return "wmatic"
    case "sushitoken":
      return "sushi"
    case "polygaj":
      return "gaj"
    case "piratebooty":
      return "booty"
    case "usd coin":
      return "usdc"
    case "waultswap polygon":
      return "wexpoly"
    case "wrapped ether":
      return "eth"
    case "wrapped btc":
      return "wbtc"
    case "quickswap":
      return "quick"
    case "dai stablecoin":
      return "dai"
    case "polysolar":
      return "solar"
    case "augury finance":
      return "omen"
    case "auroratoken":
      return "aurora"
    case "tether usd":
      return "usdt"
    case "apeswapfinance banana":
      return "banana"
    case "chainlink":
      return "link" 
    case "aga rewards":
      return "agar"
    case "aga carbon credit":
      return "agac" 
    case "aga carbon rewards":
      return "agacr"  
    case "nebulatoken":
      return "nebula" 
    case "polywolf":
      return "moon"
    case "moonwolf.io":
      return "wolf"
    case "celsius":
      return "cel"
    default:
      return clean 
  } 
   
  
}

export const doWeHaveIt = (tokenName) => {

  if (typeof tokenConfigs[tokenName] === 'undefined') {


    return false
  } else {
    return true
  }
}

export const safeCompOrCleanName = (tokenName, component ) => {
  let clean = cleanFetchedName(tokenName)
  if ( doWeHaveIt(clean) ) {
    return component 
  } else {
    return clean
 }

  
}