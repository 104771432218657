export const degenesis = {
	"address": "0x2462FA2Bc4B0Cbc501c8546081556fF4F84162De",
	"abi": require("../abis/CandleDegenesisABI.json")
}


export const dialog = {
	"0": {
		"text": [
			"In a world ruled by fear, the meaning of life began to change. King Cold was the first to ascend. He used his will to confront the Devil.",
			"This is the day we were born."],
		"options": ["What happened?", "Who is King Cold?","Haven't we done this before?"],
		"optiontarget": ["1","2","7"],
		"speaker": require("../images/kul.png")
	},
	"1": {
		"text": [
			"People stopped dying. Time stood still.",
			"Eventually it brought us peace."],
		"options": ["What do you mean?", "How long have you been here?"],
		"optiontarget": ["3","3"],
		"speaker": require("../images/kul.png")
	},
	"2": {
		"text": [
			"He is who we have to thank for all of this."],
		"options": ["What do you mean?", "How long have you been here?"],
		"optiontarget": ["3","3"],
		"speaker": require("../images/kul.png")
	},
	"3": {
		"text": [
			"We have all been here for centuries.",
			"Some grew to love it while others grew bored.",
			"Over time we became relatively advanced. In a world without death, we all must coexist. But they kept looking for something else."],
		"options": ["Who are they?", "What did they do?"],
		"optiontarget": ["4","5"],
		"speaker": require("../images/kul.png")
	},
	"4": {
		"text": [
			"The Cult."],
		"options": ["What were they looking for?"],
		"optiontarget": ["5"],
		"speaker": require("../images/kul.png")
	},
	"5": {
		"text": [
			"They started experimenting with older texts and found a way to ascend.",
			"It became the only way to leave."],
		"options": ["Where do they go?"],
		"optiontarget": ["6"],
		"speaker": require("../images/kul.png")
	},
	"6": {
		"text": [
			"No one knows where they go.",
			"They leave everything behind. Their lives, their homes, even their kids.",
			"The Cult lives on through them and the relics."],
		"options": ["What are these relics?","What happens to the kids?","What is this cult?"],
		"optiontarget": ["21","22","23"],
		"speaker": require("../images/kul.png")
	},
	"7": {
		"text": [
			"That's entirely possible, it is hard to be sure which version of yourself you are.",
			"It's also possible this is the first time I am meeting you; and  you recall a meeting which, in my perspective, has yet to occur.",
			"What have you come to do?"],
		"options": ["Explore","Farm"],
		"optiontarget": ["8","9"],
		"speaker": require("../images/kul.png")
	},
	"8": {
		"text": [
			"Ah... an explorer...",
			"But for what purpose do you explore?",
			"Surely you have a purpose."],
		"options": ["...to Earn","...to Learn"],
		"optiontarget": ["10","11"],
		"speaker": require("../images/kul.png")
	},
	"9": {
		"text": [
			"Farm? I have no idea what you mean.",
			"Those consumed by greed must begin again."],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kul.png")
	},
	"10": {
		"text": [
			"Earn? An antiquated idea in which I have no interest.",
			"Those consumed by greed must begin again."],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kul.png")
	},
	"11": {
		"text": [
			"Then learn you shall.",
			"Ask me a question?"],
		"options": ["What is a Skully?","Who are you?"],
		"optiontarget": ["12","14"],
		"speaker": require("../images/kul.png")
	},
	"12": {
		"text": [
			"The Skully is a magical symbol. Drawn in ritual chalk it can even open portals between worlds. Drawn in regular chalk, it is used to mark Cult friendly businesses and establishments.",
			"What else would you like to know?"],
		"options": ["What is a Ghostly?","Who are you?"],
		"optiontarget": ["13","14"],
		"speaker": require("../images/kul.png")
	},
	"13": {
		"text": [
			"A Ghostly is a mysterious entity that is most commonly found lingering around old ritual grounds.",
			"Can I answer something else for you?"],
		"options": ["Who are you?"],
		"optiontarget": ["14","13"],
		"speaker": require("../images/kul.png")
	},
	"14": {
		"text": [
			"My projection in your universe is called Grim.",
			"But in truth, I am most often called Death.",
			"I precede most of all things in most of all universes in most of all iterations of the Multiverse."],
		"options": ["Do you know where I can find the farms?","What do you mean by projection?"],
		"optiontarget": ["9","15"],
		"speaker": require("../images/kul.png")
	},
	"15": {
		"text": [
			"A projection in this sense, is the four-dimensional cross-section of a higher dimensional entity.",
			"I no longer have time for this. But I'll send you to see my good friend Kali.",
			"She'll ask you for a password. I won't tell you it but here's a hint: the answer is the name of one of my Earthly projections."
			],
		"options": ["Travel to Kali"],
		"optiontarget": ["16"],
		"speaker": require("../images/kul.png")
	},
	"16": {
		"text": [
			"I bring the end of earthly days, a peaceful release in life's maze.",
			"Feared by some, but solace I bring, to those whose journey here is ending.",
			"A shadow that follows the bright sun,",
			"This answer, a timeless one.",
			"Who am I?"],
		"options": ["Aether","Hemera","Thanatos","Hypnos","Eris","Please just let me in..."],
		"optiontarget": ["15","15","17","15","15","15"],
		"speaker": require("../images/kali.png")
	},
	"17": {
		"text": [
			"Nice to meet ya. Name's Kali.",
			"Looks like Grim sent you this way. Probably another one of y'all lookin for the farms.",
			"I won't tell ya where they are, but if you ask me the right questions you might find a few of em."],
		"options": ["Where are the farms?","Do you have any $CULT?","Do you have any $xCULT?","Tell me something I don't know."],
		"optiontarget": ["18","19","20","24"],
		"speaker": require("../images/kali.png")
	},
	"18": {
		"text": [
			"What did I just tell ya?",
			"Those consumed by greed must begin again."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},
	"19": {
		"text": [
			"I have some $CULT laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["19","19","pool_cult","19"],
		"speaker": require("../images/kali.png")
	},
	"20": {
		"text": [
			"I have some $xCULT laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["20","pool_xcult","20","20"],
		"speaker": require("../images/kali.png")
	},
	"21": {
		"text": [
			"The relics are a part of their rituals.",
			"According to the scrolls, they use candles, chalk, and wands.",
			"There are books with more details but they are difficult to come by."],
		"options": ["What is this cult?","What happens to the kids?"],
		"optiontarget": ["23","22"],
		"speaker": require("../images/kul.png")
	},
	"22": {
		"text": [
			"Most of them study the scrolls in hopes of ascending..",
			"You can typically find them hanging around the auction house where they sell relics."],
		"options": ["What is this cult?"],
		"optiontarget": ["23"],
		"speaker": require("../images/kul.png")
	},
	"23": {
		"text": [
			"That is something you must discover on your own. "],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kul.png")
	},
	"24": {
		"text": [
			"Hmmm... something you don't know?",
			"I've a bit of problem controlling my temper.",
			"Maybe that's why in some universes I am called the destroyer."],
		"options": ["What have you destroyed?","What do you mean by other universes?"],
		"optiontarget": ["30","40"],
		"speaker": require("../images/kali.png")
	},
	"30": {
		"text": [
			"Oh pretty much anything you can think of.",
			"Universes, planets, nations, relationships... you name it, I've destroyed it.",
			"Though, I think I've calmed down a bit."
			],
		"options": ["Good for you!","That's terrifying."],
		"optiontarget": ["31","32"],
		"speaker": require("../images/kali.png")
	},
	"31": {
		"text": [
			"Yep, one shouldn't limit themselves to a single talent after all.",
			"This break from destruction has allowed me to hone all sorts of skills.",
			"I've gotten particularly good at creating pairs of my favorite things."
			],
		"options": ["..pairs?","That seems childish."],
		"optiontarget": ["33","34"],
		"speaker": require("../images/kali.png")
	},
	"32": {
		"text": [
			"Maybe...",
			"**Kali turns away and disappears into the darkness."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},

	"33": {
		"text": [
			"Yeah like for example I pair $CULT and $xCULT with all sorts of things"
			],
		"options": ["Do you have any $CULT-$SOUL LP?","Do you have any $xCULT-$SOUL LP?"],
		"optiontarget": ["35","36"],
		"speaker": require("../images/kali.png")
	}, 
	"34": {
		"text": [
			"**Kali grows to a massive size and smashes your body between her index-finger and thumb."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},

	"35": {
		"text": [
			"I have some $CULT-$SOUL LP laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["35","35","35","pool_cultlp"],
		"speaker": require("../images/kali.png")
	},
	"36": {
		"text": [
			"I have some $xCULT-$SOUL LP laying around here somewhere, look around."],
		"options": ["Check Kali's Dresser","Check Kali's Desk","Check Kali's Nightstand","Check Under Kali's Bed"],
		"optiontarget": ["pool_xcultlp","36","36","36"],
		"speaker": require("../images/kali.png")
	},
	"40": {
		"text": [
			"Well surely you know this isn't the only one."
			],
		"options": [],
		"optiontarget": [],
		"speaker": require("../images/kali.png")
	},
}